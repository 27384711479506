import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import QueryService from "../../services/query-service";

//components
import Aside from "../../components/Aside/Aside";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import ApplicationsAmountCard from "../../components/ApplicationsAmountCard/ApplicationsAmountCard";
import Button from "../../components/UI/Button/Button";
import PageSubtitle from "../../components/UI/PageSubtitle/PageSubtitle";
import CustomDateInput from "../../components/UI/CustomDateInput/CustomDateInput";
import ToogleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";

//Learning Locker config
import learningLockerConfig from "../../constants/learning-locker-config";

//styles
import "./style.scss";

//constants
const PAGE_TITLE = "CWTL Ukraine Metrics";

const queryService = new QueryService(learningLockerConfig.url, learningLockerConfig.key, learningLockerConfig.secret);

export default function CwtlStatisticsPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [showExtrapolateData, setShowExtrapolateData] = useState(true);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndtDate] = useState(new Date());

    const [allTimeMetrics, setAllTimeMetrics] = useState({
        extrapolateRatio: 0,
        accepted: 0,
        rejected: 0,
        registrations: 0,
        activeUsers: 0,
        engagedUsers: 0,
        startLessons: 0,
        completeLessons: 0,
        startExercises: 0,
        startMinigames: 0,
        startVideos: 0,
        startBooks: 0,
        startWithoutType: 0,
        completeExercises: 0,
        completeMinigames: 0,
        completeVideos: 0,
        completeBooks: 0,
        completeWithoutType: 0,
        playtime: 0,
        playtimeMinigames: 0,
        playtimeVideos: 0,
        playtimeBooks: 0,
        playtimeWithoutType: 0,
    });

    useEffect(() => {
        // run();
        setIsLoading(false);
    }, []);

    async function run() {
        setIsLoading(true);

        const startDateOfWeek = convertTZ(startDate, "Europe/Kyiv", "start");
        const endDateOfWeek = convertTZ(endDate, "Europe/Kyiv");

        const allTimeDataCollectionConsentMetrics = await queryService.getDataCollectionConsentMetrics();

        const { accepted, rejected, acceptedRatio } = allTimeDataCollectionConsentMetrics;

        await getWeeklyData(startDateOfWeek, endDateOfWeek, accepted, rejected, acceptedRatio).then((response) => {
            setAllTimeMetrics(response);
            setIsLoading(false);
        });
    }

    async function getWeeklyData(startDate, endDate, accepted, rejected, extrapolateRatio) {
        const activeUsers = await queryService.getNumberOfActiveUsersFromTo(startDate, endDate);
        const engagedUsers = await queryService.getNumberOfEngagedUsersFromTo(startDate, endDate);

        const registrations = await queryService.getMetricData(startDate, endDate, "registrations");

        // const lessons = await queryService.getMetricData(startDate, endDate, 'lessons');
        // let startLessons = 0;
        // let completeLessons = 0;
        // lessons.length > 0 &&
        //     lessons.forEach((item) => {
        //         switch (item._id) {
        //             case 'http://activitystrea.ms/schema/1.0/start':
        //                 startLessons = item.amount;
        //                 break;
        //             case 'http://activitystrea.ms/schema/1.0/complete':
        //                 completeLessons = item.amount;
        //                 break;
        //             default:
        //         }
        //     });

        // const startExercisesByType = await queryService.getMetricData(startDate, endDate, 'startExercisesByTypeQuery');
        // let startMinigames = 0;
        // let startVideos = 0;
        // let startBooks = 0;
        // let startWithoutType = 0;
        // startExercisesByType.length > 0 &&
        //     startExercisesByType.forEach((item) => {
        //         switch (item._id) {
        //             case 'Minigames':
        //                 startMinigames = item.amount;
        //                 break;
        //             case 'Video':
        //                 startVideos = item.amount;
        //                 break;
        //             case 'Book':
        //                 startBooks = item.amount;
        //                 break;
        //             default:
        //                 startWithoutType = item.amount;
        //         }
        //     });

        // const startExercises =
        //     startExercisesByType.length > 0
        //         ? startExercisesByType.reduce((prev, cur) => {
        //               return prev + cur.amount;
        //           }, 0)
        //         : 0;

        // const comleteExercisesByTypeQuery = await queryService.getMetricData(
        //     startDate,
        //     endDate,
        //     'comleteExercisesByTypeQuery'
        // );
        // let completeMinigames = 0;
        // let completeVideos = 0;
        // let completeBooks = 0;
        // let completeWithoutType = 0;
        // comleteExercisesByTypeQuery.length > 0 &&
        //     comleteExercisesByTypeQuery.forEach((item) => {
        //         switch (item._id) {
        //             case 'Minigames':
        //                 completeMinigames = item.amount;
        //                 break;
        //             case 'Video':
        //                 completeVideos = item.amount;
        //                 break;
        //             case 'Book':
        //                 completeBooks = item.amount;
        //                 break;
        //             default:
        //                 completeWithoutType = item.amount;
        //         }
        //     });
        // const completeExercises =
        //     comleteExercisesByTypeQuery.length > 0
        //         ? comleteExercisesByTypeQuery.reduce((prev, cur) => {
        //               return prev + cur.amount;
        //           }, 0)
        //         : 0;

        // const playtimeRequest = await queryService.getMetricData(startDate, endDate, 'exercisesPlaytime');

        // let playtimeMinigames = 0;
        // let playtimeVideos = 0;
        // let playtimeBooks = 0;
        // let playtimeWithoutType = 0;

        // playtimeRequest.length > 0 &&
        //     playtimeRequest.forEach((item) => {
        //         switch (item._id) {
        //             case 'Minigames':
        //                 playtimeMinigames = item.playtime;
        //                 break;
        //             case 'Video':
        //                 playtimeVideos = item.playtime;
        //                 break;
        //             case 'Book':
        //                 playtimeBooks = item.playtime;
        //                 break;
        //             default:
        //                 playtimeWithoutType = item.playtime;
        //         }
        //     });
        // const playtime =
        //     playtimeRequest.length > 0
        //         ? playtimeRequest.reduce((prev, cur) => {
        //               return prev + cur.playtime;
        //           }, 0)
        //         : 0;

        let startLessons = 0;
        let completeLessons = 0;
        let startExercises = 0;
        let startMinigames = 0;
        let startVideos = 0;
        let startBooks = 0;
        let startWithoutType = 0;
        let completeExercises = 0;
        let completeMinigames = 0;
        let completeVideos = 0;
        let completeBooks = 0;
        let completeWithoutType = 0;
        let playtime = 0;
        let playtimeMinigames = 0;
        let playtimeVideos = 0;
        let playtimeBooks = 0;
        let playtimeWithoutType = 0;

        return {
            extrapolateRatio,
            accepted,
            rejected,
            registrations,
            activeUsers,
            engagedUsers,
            startLessons,
            completeLessons,
            startExercises,
            startMinigames,
            startVideos,
            startBooks,
            startWithoutType,
            completeExercises,
            completeMinigames,
            completeVideos,
            completeBooks,
            completeWithoutType,
            playtime,
            playtimeMinigames,
            playtimeVideos,
            playtimeBooks,
            playtimeWithoutType,
        };
    }

    const extrapolateDate = (data) => {
        if (data === 0) {
            return data;
        }
        return Math.round(data / allTimeMetrics.extrapolateRatio);
    };

    const showExtrapolateDataHandler = () => {
        setShowExtrapolateData((prev) => !prev);
    };

    const download = () => {
        run();
    };

    const startDateHandler = (value) => {
        setStartDate(value);
    };

    const endDateHandler = (value) => {
        setEndtDate(value);
    };

    function convertTZ(date, tzString, type) {
        let newDate;

        if (type === "start") {
            newDate = new Date(typeof date === "string" ? new Date(date) : date).setHours(0, 0, 0, 0);
        } else {
            newDate = new Date(typeof date === "string" ? new Date(date) : date).setHours(23, 59, 59, 999);
        }

        return new Date(typeof newDate === "string" ? new Date(newDate) : newDate).toLocaleString("EN-en", {
            timeZone: tzString,
        });
    }

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>

            <div className='main-wrapper'>
                <Aside />

                <main className='container'>
                    <div className='cwtl-statistics-page page'>
                        <PageTitle pageTitle={PAGE_TITLE} />

                        <div className='cwtl-statistics-page-control'>
                            <div>From:</div>

                            <CustomDateInput value={startDate} handler={startDateHandler} required={false} />

                            <div>To:</div>

                            <CustomDateInput value={endDate} handler={endDateHandler} required={false} />

                            <div>Show extrapolated data:</div>

                            <ToogleSwitch isToggled={showExtrapolateData} onToggle={showExtrapolateDataHandler} />

                            <Button content='Update' handler={download} />
                        </div>

                        <PageSubtitle pageSubtitle='Users' />
                        <div className='unicef-applications-dashboard cwtl-applications-dashboard'>
                            <ApplicationsAmountCard
                                type='multiple'
                                title='Extrapolate ratio'
                                value={
                                    typeof allTimeMetrics.extrapolateRatio === "number"
                                        ? (allTimeMetrics.extrapolateRatio * 100).toFixed(2) + "%"
                                        : allTimeMetrics.extrapolateRatio
                                }
                                otherValues={[
                                    { title: "Accepted", value: allTimeMetrics.accepted },
                                    { title: "Rejected", value: allTimeMetrics.rejected },
                                ]}
                            />

                            <ApplicationsAmountCard
                                title='Registrations'
                                value={allTimeMetrics.registrations}
                                description='Total unique number of people registered.'
                            />

                            <ApplicationsAmountCard
                                title={`Active users ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.activeUsers) : allTimeMetrics.activeUsers}
                                description='Users that spend any time different from 0 within the app.'
                            />

                            <ApplicationsAmountCard
                                title={`Engaged users ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.engagedUsers) : allTimeMetrics.engagedUsers}
                                description='Users who started at least 2 bubbles and spent no less than 20 minutes in the app.'
                            />
                        </div>

                        <PageSubtitle pageSubtitle='Lessons' />
                        <div className='unicef-applications-dashboard cwtl-applications-dashboard'>
                            <ApplicationsAmountCard
                                color='green'
                                title={`Started lessons ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.startLessons) : allTimeMetrics.startLessons}
                            />

                            <ApplicationsAmountCard
                                color='green'
                                title={`Completed lessons ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.completeLessons) : allTimeMetrics.completeLessons}
                            />
                        </div>

                        <PageSubtitle pageSubtitle='Exercises' />
                        <div className='unicef-applications-dashboard cwtl-applications-dashboard'>
                            <ApplicationsAmountCard
                                type='multiple'
                                color='orange'
                                title={`Started exercises ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.startExercises) : allTimeMetrics.startExercises}
                                otherValues={[
                                    {
                                        title: "Minigames",
                                        value: showExtrapolateData ? extrapolateDate(allTimeMetrics.startMinigames) : allTimeMetrics.startMinigames,
                                    },
                                    {
                                        title: "Videos",
                                        value: showExtrapolateData ? extrapolateDate(allTimeMetrics.startVideos) : allTimeMetrics.startVideos,
                                    },
                                    {
                                        title: "Books",
                                        value: showExtrapolateData ? extrapolateDate(allTimeMetrics.startBooks) : allTimeMetrics.startBooks,
                                    },
                                    {
                                        title: "Unknown",
                                        value: showExtrapolateData
                                            ? extrapolateDate(allTimeMetrics.startWithoutType)
                                            : allTimeMetrics.startWithoutType,
                                    },
                                ]}
                            />

                            <ApplicationsAmountCard
                                type='multiple'
                                color='orange'
                                title={`Completed exercises ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={showExtrapolateData ? extrapolateDate(allTimeMetrics.completeExercises) : allTimeMetrics.completeExercises}
                                otherValues={[
                                    {
                                        title: "Minigames",
                                        value: showExtrapolateData
                                            ? extrapolateDate(allTimeMetrics.completeMinigames)
                                            : allTimeMetrics.completeMinigames,
                                    },
                                    {
                                        title: "Videos",
                                        value: showExtrapolateData ? extrapolateDate(allTimeMetrics.completeVideos) : allTimeMetrics.completeVideos,
                                    },
                                    {
                                        title: "Books",
                                        value: showExtrapolateData ? extrapolateDate(allTimeMetrics.completeBooks) : allTimeMetrics.completeBooks,
                                    },
                                    {
                                        title: "Unknown",
                                        value: showExtrapolateData
                                            ? extrapolateDate(allTimeMetrics.completeWithoutType)
                                            : allTimeMetrics.completeWithoutType,
                                    },
                                ]}
                            />
                        </div>

                        <PageSubtitle pageSubtitle='Playtime' />
                        <div className='unicef-applications-dashboard cwtl-applications-dashboard'>
                            <ApplicationsAmountCard
                                type='multiple'
                                color='pink'
                                title={`Exercises playtime ${showExtrapolateData ? "(extrapolated)" : ""}`}
                                value={
                                    showExtrapolateData
                                        ? (extrapolateDate(allTimeMetrics.playtime) / 3600).toFixed(2) + " hours"
                                        : (allTimeMetrics.playtime / 3600).toFixed(2) + " hours"
                                }
                                otherValues={[
                                    {
                                        title: "Minigames",
                                        value: showExtrapolateData
                                            ? (extrapolateDate(allTimeMetrics.playtimeMinigames) / 3600).toFixed(2) + " hours"
                                            : (allTimeMetrics.playtimeMinigames / 3600).toFixed(2) + " hours",
                                    },
                                    {
                                        title: "Videos",
                                        value: showExtrapolateData
                                            ? (extrapolateDate(allTimeMetrics.playtimeVideos) / 3600).toFixed(2) + " hours"
                                            : (allTimeMetrics.playtimeVideos / 3600).toFixed(2) + " hours",
                                    },
                                    {
                                        title: "Books",
                                        value: showExtrapolateData
                                            ? (extrapolateDate(allTimeMetrics.playtimeBooks) / 3600).toFixed(2) + " hours"
                                            : (allTimeMetrics.playtimeBooks / 3600).toFixed(2) + " hours",
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
        </>
    );
}
