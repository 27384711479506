import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// hooks
import useIsMount from "../../../hooks/useIsMount";

// api
import axios from "axios";
import API from "../../../app/api";

// components
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import Loader from "../../../components/Loader/Loader";
import Input from "../../../components/UI/Nush/Input/Input";
import CustomCheckBox from "../../../components/UI/CustomCheckBox/CustomCheckBox";
import Modal from "../../../components/ecw-applications/Modal/Modal";

// styles
import "./style.scss";

// regular expressions
const numberRegExp = new RegExp(/^\d+$/);
const nameRegExp = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-]+$/, "g");
const emailRegExp = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
    "i"
);

// list options
const ACTIVITIES_LIST = {
    1: "Тренінг для тренерів ВШО",
    2: "Тренінги для вчителів з впровадження ВШО",
    3: "Тренінг для тренерів НУШ 1-4 класи",
    4: "Тренінги з навчання вчителів у регіонах НУШ 1-4 класи",
    5: "Тренінг для тренерів «Вивчаю – не чекаю»",
    6: "Тренінги для вчителів «Вивчаю – не чекаю»",
};

const FORMAT_OPTIONS = ["Онлайн (Zoom, Google Meet)", "Офлайн (наживо)"];

const GENDER_OPTIONS = ["Жінка", "Чоловік"];

const EcwFormPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isMount = useIsMount();

    useEffect(() => {
        const id = searchParams.get("activity");
        if (id && ACTIVITIES_LIST[id]) {
            setActivity(ACTIVITIES_LIST[id]);
        } else {
            setActivity(ACTIVITIES_LIST[1]);
            // navigate("/404");
        }
    }, [searchParams, navigate]);

    const [isFormSent, setIsFormSent] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [activity, setActivity] = useState("");

    const [format, setFormat] = useState(undefined);

    const [firstName, setFirstName] = useState("");
    const [firstNameValidation, setFirstNameValidation] = useState(true);
    const [firstNameValidationMsg, setFirstNameValidationMsg] = useState(undefined);

    const [lastName, setLastName] = useState("");
    const [lastNameValidation, setLastNameValidation] = useState(true);
    const [lastNameValidationMsg, setLastNameValidationMsg] = useState(undefined);

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(true);
    const [emailValidationMsg, setEmailValidationMsg] = useState(undefined);

    const [phone, setPhone] = useState("");
    const [phoneValidation, setPhoneValidation] = useState(true);
    const [phoneValidationMsg, setPhoneValidationMsg] = useState(undefined);

    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(undefined);

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(undefined);

    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState(undefined);

    const [settlements, setSettlements] = useState([]);
    const [settlement, setSettlement] = useState(undefined);

    const [gender, setGender] = useState(undefined);

    const [isReportingAllowed, setIsReportingAllowed] = useState(true);

    const [isCommunicationAllowed, setIsCommunicationAllowed] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(API.ecwLocations.regionsRead)
            .then((response) => {
                let { data } = response;
                setRegions(
                    data
                        .map((region) => region.region)
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                );
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, []);

    useEffect(() => {
        if (region) {
            setIsLoading(true);
            axios
                .post(API.ecwLocations.districtsRead, {
                    region: region,
                })
                .then((response) => {
                    let { data } = response;
                    setDistricts(data.map((district) => district.district));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region]);

    useEffect(() => {
        if (region && district) {
            setIsLoading(true);
            axios
                .post(API.ecwLocations.communitiesRead, {
                    region: region,
                    district: district,
                })
                .then((response) => {
                    let { data } = response;
                    setCommunities(data.map((community) => community.community));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district]);

    useEffect(() => {
        if (region && district && community) {
            setIsLoading(true);
            axios
                .post(API.ecwLocations.settlementsRead, {
                    region: region,
                    district: district,
                    community: community,
                })
                .then((response) => {
                    let { data } = response;
                    setSettlements([...data.map((settlement) => settlement.settlement)]);
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district, community]);

    const clearDistrict = () => {
        setDistrict(undefined);
        setDistricts([]);
    };

    const clearCommunity = () => {
        setCommunity(undefined);
        setCommunities([]);
    };

    const clearSettlement = () => {
        setSettlement(undefined);
        setSettlements([]);
    };

    const nameValidation = (setValidation, setValidationMsg, field, value) => {
        const nameRegExp = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-]+$/, "g");
        if (value.length < 2) {
            setValidation(false);
            return setValidationMsg(field + " повинно мати як мінімум дві літери.");
        } else if (!nameRegExp.test(value)) {
            setValidation(false);
            return setValidationMsg(field + " повинно мати тільки українські літери та не містити пробілів.");
        } else {
            setValidation(true);
            return setValidationMsg(undefined);
        }
    };

    const firstNameHandler = (e) => setFirstName(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        return nameValidation(setFirstNameValidation, setFirstNameValidationMsg, "Iм'я", firstName);
    }, [firstName]);

    const lastNameHandler = (e) => setLastName(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        return nameValidation(setLastNameValidation, setLastNameValidationMsg, "Прізвище", lastName);
    }, [lastName]);

    const emailHandler = (e) => setEmail(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        if (!emailRegExp.test(email)) {
            setEmailValidation(false);
            setEmailValidationMsg("Вказано некоректну електронну адресу.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(undefined);
        }
    }, [email]);

    const phoneHandler = (e) => setPhone(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        if (!numberRegExp.test(phone)) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити тільки цифри.");
        } else if (phone.length !== 9) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити 9 цифр.");
        } else {
            setPhoneValidation(true);
            setPhoneValidationMsg(undefined);
        }
    }, [phone]);

    const formatHandler = (option) => {
        setFormat(option);
    };

    const regionHandler = (option = undefined) => {
        if (option === undefined) {
            setRegion(undefined);
        } else {
            setRegion(option);
        }
        clearDistrict();
        clearCommunity();
        clearSettlement();
    };

    const districtHandler = (option = undefined) => {
        if (option === undefined) {
            setDistrict(undefined);
        } else {
            setDistrict(option);
        }
        clearCommunity();
        clearSettlement();
    };

    const communityHandler = (option = undefined) => {
        if (option === undefined) {
            setCommunity(undefined);
        } else {
            setCommunity(option);
        }
        clearSettlement();
    };

    const settlementHandler = (option = undefined) => {
        if (option === undefined) return setSettlement(undefined);
        return setSettlement(option);
    };

    const genderHandler = (value) => setGender(value);
    const isReportingAllowedHandler = () => setIsReportingAllowed((prev) => !prev);
    const isCommunicationAllowedHandler = () => setIsCommunicationAllowed((prev) => !prev);

    const closeModalHandler = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (!activity) return setIsFormValid(false);
        if (!format) return setIsFormValid(false);
        if (!firstName || firstNameValidationMsg) return setIsFormValid(false);
        if (!lastName || lastNameValidationMsg) return setIsFormValid(false);
        if (!email || emailValidationMsg) return setIsFormValid(false);
        if (!phone || phoneValidationMsg) return setIsFormValid(false);
        if (!region) return setIsFormValid(false);
        if (!district) return setIsFormValid(false);
        if (!community) return setIsFormValid(false);
        if (!settlement) return setIsFormValid(false);
        if (!gender) return setIsFormValid(false);
        if (!isReportingAllowed) return setIsFormValid(false);
        return setIsFormValid(true);
    }, [
        activity,
        format,
        firstName,
        firstNameValidationMsg,
        lastName,
        lastNameValidationMsg,
        email,
        emailValidationMsg,
        phone,
        phoneValidationMsg,
        region,
        district,
        community,
        settlement,
        gender,
        isReportingAllowed,
    ]);

    const sendForm = () => {
        if (isFormValid) {
            axios
                .post(API.application.ecw.ecwApplicationsCreate, {
                    activity,
                    format,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    phone: `+380${phone}`,
                    region,
                    district,
                    community,
                    settlement,
                    gender,
                    communication: isCommunicationAllowed,
                })
                .then((response) => {
                    setIsFormSent(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Реєстрація</title>
            </Helmet>

            <div className="container">
                <main className="ecw-form-page">
                    <h1>{activity}</h1>

                    <div className="ecw-form-page-alert">
                        <div className="ecw-form-page-alert-title">
                            <div>!</div>
                            <h2>Повідомлення про збір та обробку даних</h2>
                        </div>
                        <div className="ecw-form-page-alert-content">
                            Просимо вас надавати коректну та актуальну інформацію при заповненні форми. Ця інформація допоможе нам
                            проаналізувати стан освіти в України з урахуванням регіональних та гендерних аспектів. Також дані будуть
                            узагальнено використані для звітування у межах комунікації про проєкт. Обробка даних складається зі знеособлених
                            даних, тож не несе ризику порушення прав і свобод пов'язаних із приватністю. Ми не передаватимемо ваші контактні
                            дані третім сторонам.
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Формат проведення"
                                value={format}
                                options={FORMAT_OPTIONS}
                                handler={formatHandler}
                                required={true}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <Input
                                label="Вкажіть будь ласка ваше Прізвище (у форматі - Колісник)"
                                value={lastName}
                                handler={lastNameHandler}
                                validation={lastNameValidation}
                                validationMsg={lastNameValidationMsg}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <Input
                                label="Вкажіть будь ласка ваше Ім'я (у форматі - Ольга)"
                                value={firstName}
                                handler={firstNameHandler}
                                validation={firstNameValidation}
                                validationMsg={firstNameValidationMsg}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <Input
                                label="Електронна пошта"
                                value={email}
                                handler={emailHandler}
                                validation={emailValidation}
                                validationMsg={emailValidationMsg}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <Input
                                label="Вкажіть будь ласка ваш номер телефону (у форматі - +380991112200)"
                                value={phone}
                                handler={phoneHandler}
                                validation={phoneValidation}
                                validationMsg={phoneValidationMsg}
                                required={true}
                                type="phone"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Область"
                                value={region}
                                options={regions}
                                handler={regionHandler}
                                required={true}
                                disabled={false}
                            />
                        </div>

                        <div className="form-col-2">
                            <CustomSelect
                                label="Район"
                                value={district}
                                options={districts}
                                handler={districtHandler}
                                required={true}
                                disabled={region ? false : true}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Громада"
                                value={community}
                                options={communities}
                                handler={communityHandler}
                                required={true}
                                disabled={region && district ? false : true}
                            />
                        </div>

                        <div className="form-col-2">
                            <CustomSelect
                                label="Населений пункт"
                                value={settlement}
                                options={settlements}
                                handler={settlementHandler}
                                required={true}
                                disabled={region && district && community ? false : true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect label="Стать" value={gender} options={GENDER_OPTIONS} handler={genderHandler} required={true} />
                        </div>
                    </div>

                    <div className="form-row">
                        <CustomCheckBox
                            label={
                                "Я погоджуюся на використання моїх даних для узагальненої звітності та комунікації щодо результатів активностей у межах проєкту."
                            }
                            value={isReportingAllowed}
                            handler={isReportingAllowedHandler}
                            required={true}
                        />
                    </div>

                    <div className="form-row">
                        <CustomCheckBox
                            label="Я погоджуюся отримувати email та телефонні розсилки з анонсами освітніх подій, тренінгів, курсів тощо."
                            value={isCommunicationAllowed}
                            handler={isCommunicationAllowedHandler}
                        />
                    </div>

                    <div className="nush-form-page-button-validations">
                        <div>{!format && "Вам потрібно обрати формат."}</div>

                        <div>{!firstName && "Вам потрібно вказати ім’я."}</div>
                        <div>{firstNameValidationMsg}</div>

                        <div>{!lastName && "Вам потрібно вказати прізвище."}</div>
                        <div>{lastNameValidationMsg}</div>

                        <div>{!email && "Вам потрібно вказати електронну адресу."}</div>
                        <div>{emailValidationMsg}</div>

                        <div>{!phone && "Вам потрібно вказати номер телефону."}</div>
                        <div>{phoneValidationMsg}</div>

                        <div>{!region && "Вам потрібно вказати область."}</div>

                        <div>{!district && "Вам потрібно вказати район."}</div>

                        <div>{!community && "Вам потрібно вказати громаду."}</div>

                        <div>{!settlement && "Вам потрібно вказати населений пункт."}</div>

                        <div>{!gender && "Вам потрібно вказати стать."}</div>

                        <div>{!isReportingAllowed && "Згода на використання даних обов’язкова."}</div>
                    </div>

                    <div className="nush-form-page-button">
                        <button onClick={sendForm} className="submit-button" disabled={!isFormValid}>
                            Надіслати
                        </button>
                    </div>

                    <div className="support">
                        Виникли питання чи труднощі під час заповнення форми? Напишіть лист на електронну адресу{" "}
                        <a href="mailto:info@osvitoria.org" target="_blank" rel="noreferrer">
                            info@osvitoria.org
                        </a>
                        , і ми обов'язково допоможемо її вирішити.
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
            {isFormSent && <Modal closeHandler={closeModalHandler} />}
        </>
    );
};

export default EcwFormPage;
