import styles from "./styles.module.scss";

export default function Modal({ closeHandler }) {
    return (
        <div className={styles.modal}>
            <div className={styles.modal__window}>
                <h2>Дякуємо, ви успішно зареєструвались!</h2>
                <p>На вашу електронну адресу було відправлено лист з підтвердженням реєстрації.</p>
                <button onClick={closeHandler}>Закрити</button>
            </div>
        </div>
    );
}
