import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

//services
import AuthService from "./services/auth.service";

//pages
import HomePage from "./pages/HomePage/HomePage";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Profile from "./pages/Profile/Profile";
import UsersPage from "./pages/UsersPage/UsersPage";
import Error from "./pages/Error/Error";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import UnicefFormPage from "./pages/UnicefFormPage/UnicefFormPage";
import UnicefApplicationsPage from "./pages/UnicefApplicationsPage/UnicefApplicationsPage";
import CoachesControlPage from "./pages/CoachesControlPage/CoachesControlPage";
import TrainingsControlPage from "./pages/TrainingsControlPage/TrainingsControlPage";
import UnicefCoursesApplicationsPage from "./pages/UnicefCoursesApplicationsPage/UnicefCoursesApplicationsPage";
import UnicefCoursesFormPage from "./pages/UnicefCoursesFormPage/UnicefCoursesFormPage";
import CwtlStatisticsPage from "./pages/CwtlStatisticsPage/CwtlStatisticsPage";
import UnicefRegistrationCheckPage from "./pages/UnicefRegistrationCheckPage/UnicefRegistrationCheckPage";

//components
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

import EventBus from "./common/EventBus";

import { useSelector, useDispatch } from "react-redux";
import { setAdminRole, setCurrentUserR, setModeratorRole, userIsLogin, userIsLogout } from "./features/authSlice";
import { selectCurrentUser, selectModeratorRole, selectAdminRole } from "./features/authSlice";

//styles
import "./reset.css";
import "./App.scss";
import Loader from "./components/Loader/Loader";
import NushCoursesFormPage from "./pages/forms/NushCoursesFormPage/NushCoursesFormPage";
import NushApplicationsPage from "./pages/NushApplicationsPage/NushApplicationsPage";
import EcwFormPage from "./pages/forms/EcwFormPage/EcwFormPage";
import EcwApplicationsPage from "./pages/EcwApplicationsPage/EcwApplicationsPage";

export default function App() {
    const isLoged = useSelector((state) => state.auth.value);
    const currentUserRedux = useSelector(selectCurrentUser);

    const hasAdminRole = useSelector(selectModeratorRole);

    const dispatch = useDispatch();

    const [showModeratorBoard, setShowModeratorBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
        const user = AuthService.getCurrentUser();

        if (user) {
            dispatch(userIsLogin());
            setCurrentUser(user);
            dispatch(setCurrentUserR(user));
            setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));

            if (user.roles.includes("ROLE_MODERATOR")) dispatch(setModeratorRole());
            if (user.roles.includes("ROLE_ADMIN")) dispatch(setAdminRole());
        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = () => {
        AuthService.logout();
        setShowModeratorBoard(false);
        setShowAdminBoard(false);
        setCurrentUser(undefined);
    };

    return (
        <>
            <Nav showAdminBoard={showAdminBoard} currentUser={currentUser} logOut={logOut} />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/trainings/ecw" element={<EcwFormPage />} />
                <Route path="/courses/nush" element={<NushCoursesFormPage />} />
                <Route path="/trainings/unicef" element={<UnicefFormPage />} />
                <Route path="/trainings/unicef/check" element={<UnicefRegistrationCheckPage />} />

                {currentUser && (
                    <>
                        <Route path="/profile" element={<Profile />} />

                        <Route path="/unicef-applications" element={<UnicefApplicationsPage />} />
                        <Route path="/unicef-courses-applications" element={<UnicefCoursesApplicationsPage />} />
                        <Route path="/nush-courses-applications" element={<NushApplicationsPage />} />
                        <Route path="/ecw-applications" element={<EcwApplicationsPage />} />

                        <Route path="/courses/unicef" element={<UnicefCoursesFormPage />} />
                        <Route path="/statistics/cwtl" element={<CwtlStatisticsPage />} />
                    </>
                )}

                {showAdminBoard && (
                    <>
                        <Route path="/coaches-control-page" element={<CoachesControlPage />} />
                        <Route path="/trainings-control-page" element={<TrainingsControlPage />} />

                        <Route path="/register" element={<Register />} />
                        <Route path="/users" element={<UsersPage />} />
                    </>
                )}
            </Routes>
            <Footer />
            {isLoading && <Loader />}
        </>
    );
}
