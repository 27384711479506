import parseDate from "../../../features/parseDate";

import "./style.scss";

const ApplicationsTable = ({ applications, deleteApplicationHandler }) => {
    if (applications && applications.length > 0) {
        return (
            <>
                <table className="ecw-applications-records">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Активність</th>
                            <th>Форма</th>
                            <th>Ім'я учасника</th>
                            <th>Прізвище учасника</th>
                            <th>Дата</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.map((application) => {
                            return (
                                <tr key={application.id}>
                                    <th>{application.id}</th>
                                    <th>{application.activity}</th>
                                    <th>{application.format}</th>
                                    <th>{application.first_name}</th>
                                    <th>{application.last_name}</th>
                                    <th>{parseDate(application.createdAt).split(",")[0]}</th>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    } else {
        return <div className="unicef-applications-records-not-found">За цим критерієм реєстрацій не знайдено!</div>;
    }
};

export default ApplicationsTable;
