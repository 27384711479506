import { useSelector } from 'react-redux';

//states
import { selectCurrentUser } from '../../features/authSlice';

//styles
import './style.scss';

//icons
import messageIcon from '../../assets/icons/message.svg';
import instagramIcon from '../../assets/icons/social/instagram.svg';
import facebookIcon from '../../assets/icons/social/facebook.svg';
import youtubeIcon from '../../assets/icons/social/youtube.svg';

//constants
const SUPPORT_EMAIL = 'cto@osvitoria.org';
const FACEBOOK_LINK = 'https://www.facebook.com/osvitoria.media/';
const INSTAGRAM_LINK = 'https://www.instagram.com/osvitoria_official/';
const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCz3iBih-MsBlBvhIgkHuRzQ/';

export default function Footer() {
    const currentUser = useSelector(selectCurrentUser);

    if (currentUser) {
        return (
            <footer className='footer'>
                <div className='container'>
                    <div className='footer-divider'>
                        <div className='footer-title'>Система управління даними</div>
                        <div className='footer-info'>
                            <div className='footer-info-support-title'>Технічна підтримка:</div>
                            <div className='footer-info-support-email'>
                                <img src={messageIcon} alt='icon' />
                                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                            </div>
                        </div>
                        <div className='footer-social'>
                            <div>
                                <a href={FACEBOOK_LINK} target='_blank' rel='noreferrer'>
                                    <img src={facebookIcon} alt='social-icon' />
                                </a>
                            </div>
                            <div>
                                <a href={INSTAGRAM_LINK} target='_blank' rel='noreferrer'>
                                    <img src={instagramIcon} alt='social-icon' />
                                </a>
                            </div>
                            <div>
                                <a href={YOUTUBE_LINK} target='_blank' rel='noreferrer'>
                                    <img src={youtubeIcon} alt='social-icon' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    } else {
        return <footer className='user-footer'></footer>;
    }
}
