import { useState } from "react";
import { NavLink } from "react-router-dom";

//styles
import "./style.scss";

//icons
import usersGroupIcon from "../../assets/icons/users-group.svg";
import formIcon from "../../assets/icons/form.svg";
import databaseIcon from "../../assets/icons/database.svg";
import chartIcon from "../../assets/icons/chart.svg";

export default function Aside() {
    const [isOpen, setIsOpen] = useState(false);

    const handler = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <aside className="aside" data-open={String(isOpen)}>
            <button onClick={handler}>
                <em>Меню</em>
                <span>+</span>
            </button>
            <nav>
                <div className="aside-nav-group">
                    <div className="aside-nav-group-title">
                        <img src={formIcon} alt="icon" />
                        <span>Реєстрації</span>
                    </div>
                    <div className="aside-nav-group-links">
                        <NavLink to="/ecw-applications">Активності ECW</NavLink>
                        <NavLink to="/nush-courses-applications">Курси НУШ (UNESCO)</NavLink>
                        <NavLink to="/unicef-applications">Тренінги UNICEF</NavLink>
                        <NavLink to="/unicef-courses-applications">Курси UNICEF</NavLink>
                    </div>
                </div>

                <div className="aside-nav-group">
                    <div className="aside-nav-group-title">
                        <img src={databaseIcon} alt="icon" />
                        <span>Дані</span>
                    </div>
                    <div className="aside-nav-group-links">
                        <NavLink to="/trainings-control-page">Тренінги</NavLink>
                        <NavLink to="/coaches-control-page">Тренери</NavLink>
                    </div>
                </div>

                <div className="aside-nav-group">
                    <div className="aside-nav-group-title">
                        <img src={chartIcon} alt="icon" />
                        <span>Статистика</span>
                    </div>
                    <div className="aside-nav-group-links">
                        <NavLink to="/statistics/cwtl">Вивчаю - не чекаю</NavLink>
                    </div>
                </div>

                <div className="aside-nav-group">
                    <div className="aside-nav-group-title">
                        <img src={usersGroupIcon} alt="icon" />
                        <span>Користувачі</span>
                    </div>
                    <div className="aside-nav-group-links">
                        <NavLink to="/users">Список користувачів</NavLink>
                        <NavLink to="/register">Додати користувача</NavLink>
                    </div>
                </div>
            </nav>
        </aside>
    );
}
